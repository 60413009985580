<template>
  <div class="lang" :class="{ lang__white: white }">
    <button
      class="lang__select"
      :class="{ 'lang__select--active': language === 'de' }"
      @click="selectLanguage('de')"
    >
      DE
    </button>
    <button
      class="lang__select"
      :class="{ 'lang__select--active': language === 'en' }"
      @click="selectLanguage('en')"
    >
      EN
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  props: {
    white: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["language"]),
  },
  methods: {
    selectLanguage(lang) {
      this.$store.commit("setLanguage", lang)
    },
  },
}
</script>

<style lang="scss">
.lang {
  $block: &;
  display: flex;
  column-gap: $spacing-04;

  @include mq($until: mobile-landscape) {
    column-gap: $spacing-03;
  }

  &__select {
    background-color: initial;
    border: none;
    border-bottom: 1px solid transparent;
    color: $color-obsidian-3;
    transition: 300ms border $ease-out;
    cursor: pointer;

    @include mq($until: mobile-landscape) {
      font-size: 14px;
    }

    &--active {
      border-bottom: 1px solid $color-obsidian-6;
      color: $color-obsidian-6;
      cursor: default;
      pointer-events: none;
    }
  }

  &__white {
    #{$block}__select {
      color: rgba($color-white, 0.7);

      &--active {
        color: $color-obsidian-1;
        border-color: $color-obsidian-1;
      }
    }
  }
}
</style>
