<template>
  <div class="dialog" :class="{ 'dialog--red': red }">
    <dialog ref="dialog" class="dialog__content">
      <div class="dialog__header">
        <div v-if="dialogData.title" class="dialog__header__info">
          <BaseIcon height="32" width="32" viewBox="0 0 32 32">
            <IconInfo :color="red ? '#E40428' : '#2E3233'" />
          </BaseIcon>
          <h2 class="dialog__header__title">{{ dialogData.title }}</h2>
        </div>
        <button class="dialog__header__close">
          <BaseIcon
            height="32"
            width="32"
            viewBox="0 0 32 32"
            @click="$emit('closeDialog')"
          >
            <IconCloseLight />
          </BaseIcon>
        </button>
      </div>
      <img
        v-if="dialogData.image_limited_width?.url"
        class="dialog__image dialog__image--limited-width"
        :src="dialogData.image_limited_width.url"
      />
      <img
        v-if="dialogData.image_full_width?.url"
        class="dialog__image"
        :src="dialogData.image_full_width.url"
      />
      <h3 v-if="dialogData.short_text" class="dialog__text__short">
        {{ dialogData.short_text }}
      </h3>
      <div
        v-if="dialogData.long_text"
        class="dialog__text__long"
        v-html="dialogData.long_text.replace('\n', '<br>')"
      ></div>
      <BaseCheckbox
        v-if="dialogData.acknowledge_checkbox_text"
        :label="dialogData.acknowledge_checkbox_text"
        @checked="handleCheck($event)"
      />
      <div class="dialog__buttons">
        <BaseButton
          :style="'obsidian'"
          :class="'button--fullwidth'"
          @click="$emit('closeDialog')"
        >
          {{ dialogData.cancel_button_text }}
        </BaseButton>
        <BaseButton
          :class="'button--fullwidth'"
          :disabled="isAcceptButtonDisabled"
          @click="$emit('acceptDialog')"
        >
          {{ dialogData.next_button_text }}
        </BaseButton>
      </div>
    </dialog>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon.vue"
import IconInfo from "@/components/icons/IconInfo.vue"
import IconCloseLight from "@/components/icons/IconCloseLight.vue"
import BaseCheckbox from "@/components/BaseCheckbox.vue"
import BaseButton from "@/components/BaseButton.vue"

export default {
  emits: ["closeDialog", "acceptDialog"],
  components: {
    BaseIcon,
    IconInfo,
    IconCloseLight,
    BaseCheckbox,
    BaseButton,
  },
  props: {
    red: {
      type: Boolean,
      default: false,
    },
    dialogData: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isAcceptButtonDisabled: this.dialogData.acknowledge_checkbox_text ? Boolean(
        this.dialogData.acknowledge_checkbox_text
      ) : null,
    }
  },
  mounted() {
    window.addEventListener('click', this.clickOutside)
    window.addEventListener('keydown', this.escapeTriggered)
  },
  beforeUnmount() {
    window.removeEventListener('click', this.clickOutside)
    window.removeEventListener('keydown', this.escapeTriggered)
  },
  methods: {
    clickOutside(e) {
      const clickOutside =
        !(e.target).classList.contains('dialog__content') &&
        !(e.target).closest(`.dialog__content`)

      if (clickOutside) {
        this.$emit("closeDialog")
      }
    },
    escapeTriggered(e) {
      if (e.key === 'Escape') this.$emit('closeDialog')
    },
    handleCheck(event) {
      this.isAcceptButtonDisabled = !event
    },
  },
}
</script>

<style lang="scss">
.dialog {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color-obsidian-6, 0.4);
  z-index: $z-index-dialog;

  &--red {
    background-color: rgba($color-red-6, 0.4);
  }

  &__content {
    @include absolute-center;
    background-color: $color-white;
    border-radius: $border-radius-xl;
    border: none;
    box-shadow: $box-shadow-bottom;
    display: flex;
    flex-direction: column;
    padding: 30px;
    max-height: calc($fullheight - $spacing-05 - $spacing-05);
    max-width: 550px;
    width: calc(100vw - $spacing-05 - $spacing-05);
    overflow-y: auto;
  }

  &__header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: $spacing-08;

    &__info {
      display: flex;
      align-items: center;
      column-gap: 10px;
    }

    &__title {
      line-height: 1;
      padding-top:8px;
    }

    &__close {
      background-color: initial;
      border: none;
      cursor: pointer;
      margin-left: auto;
    }
  }

  &__image {
    align-self: center;
    width: 100%;
    margin-bottom: 35px;

    &--limited-width {
      max-width: 200px;
      margin-bottom: 60px;
    }
  }

  &__text {
    &__short {
      margin: 0 56px 70px;
      text-align: center;

      @include mq($until: mobile-landscape) {
        margin: 0 0 70px;
      }
    }
    &__long {
      font-family: $font-family-universltpro;
      font-size: 20px;
      margin-bottom: 70px;

      @include mq($until: mobile-landscape) {
        font-size: 16px;
        margin-bottom: $spacing-05;
      }
    }
  }

  .checkbox {
    margin: 0 56px;

    @include mq($until: mobile-landscape) {
      font-size: 16px;
      margin: 0;
    }
  }

  &__buttons {
    display: flex;
    justify-content: space-between;
    column-gap: 20px;
    margin-top: 56px;

    @include mq($until: mobile-landscape) {
      flex-direction: column;
      row-gap: 8px;
    }
  }
}
</style>
