<template>
  <div class="station-header">
    <div class="station-header__left">
      <button
        class="station-header__button"
        :class="{
          'station-header__button--disabled': currentStationIndex <= 0,
        }"
        @click="toPreviousStation"
      >
        <BaseIcon>
          <IconChevronLeftLight />
        </BaseIcon>
      </button>
      <p class="station-header__left__index" v-if="stationsTotal">
        {{
          translations?.station +
          " " +
          (currentStationIndex + 1) +
          " " +
          translations?.of +
          " " +
          stationsTotal
        }}
      </p>
    </div>
    <div class="station-header__right">
      <p class="station-header__right__text" v-if="nextStation">
        {{ `${translations?.next_station}: ${currentStationIndex + 2} -` }}
        <span v-html="nextStation.title"></span>
      </p>
      <button class="station-header__button" @click="changeToContents">
        <BaseIcon>
          <IconContents />
        </BaseIcon>
      </button>
      <button class="station-header__button" @click="handleNextButton">
        <BaseIcon>
          <IconChevronRightLight />
        </BaseIcon>
      </button>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import BaseIcon from "@/components/BaseIcon.vue"
import IconChevronLeftLight from "@/components/icons/IconChevronLeftLight.vue"
import IconChevronRightLight from "@/components/icons/IconChevronRightLight.vue"
import IconContents from "@/components/icons/IconContents.vue"

export default {
  components: {
    BaseIcon,
    IconChevronLeftLight,
    IconChevronRightLight,
    IconContents,
  },
  mounted() {
    window.addEventListener("keydown", this.handleKeydown, null)
  },
  beforeUnmount() {
    window.removeEventListener("keydown", this.handleKeydown)
  },
  computed: {
    ...mapGetters([
      "translations",
      "stationsData",
      "stationsTotal",
      "currentStationIndex",
    ]),
    nextStation() {
      return this.currentStationIndex + 1 < this.stationsTotal
        ? this.stationsData[this.currentStationIndex + 1]
        : null
    },
  },
  methods: {
    handleKeydown(e) {
      switch (e.keyCode) {
        case 37:
          if (this.currentStationIndex > 0) this.toPreviousStation()
          break
        case 39:
          this.handleNextButton(e)
          break
      }
    },
    handleNextButton(e) {
      e.stopPropagation()
      
      this.currentStationIndex + 1 === this.stationsTotal
        ? this.showEndTourDialog()
        : this.toNextStation()
    },
    toPreviousStation() {
      this.$router.push({
        name: "Tour",
        params: {
          tour: this.$route.params.tour,
          station:
            this.stationsData[
              this.currentStationIndex - 1
            ].station_id.toLowerCase(),
        },
      })
    },
    toNextStation() {
      this.$router.push({
        name: "Tour",
        params: {
          tour: this.$route.params.tour,
          station: this.nextStation.station_id.toLowerCase(),
        },
      })
    },
    changeToContents() {
      this.$store.commit("setIsContents", true)
    },
    showEndTourDialog() {
      this.$store.commit("setShowEndTourDialog", true)
    },
  },
}
</script>

<style lang="scss">
.station-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 20px;
  padding: 30px;

  @include mq($until: mobile-landscape) {
    padding: $spacing-05;
  }

  &__left {
    display: flex;
    align-items: center;
    column-gap: 20px;

    @include mq($until: mobile-landscape) {
      column-gap: $spacing-03;
    }

    &__index {
      white-space: nowrap;
    }
  }

  &__right {
    display: flex;
    align-items: center;
    column-gap: 10px;

    @include mq($until: mobile-landscape) {
      column-gap: $spacing-03;
    }

    &__text {
      color: $color-obsidian-3;
      margin-right: 30px;

      @include mq($until: mobile-landscape) {
        display: none;
      }
    }
  }

  &__button {
    @include flex-center;
    flex-shrink: 0;
    background-color: $color-obsidian-1-10;
    border-radius: $border-radius-xs;
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;

    @include mq($until: mobile-landscape) {
      height: 36px;
      width: 36px;
    }

    &--disabled {
      opacity: 0;
      pointer-events: none;

      @include mq($until: mobile-landscape) {
        display: none;
      }
    }
  }
}
</style>
