<template>
  <path
    d="M16 4c6.627 0 12 5.373 12 12s-5.373 12-12 12S4 22.627 4 16 9.373 4 16 4zm1.662 8.956h-4.21v.398c.472.028.79.141.955.338.165.197.247.65.247 1.359v5.865c0 .709-.071 1.146-.215 1.31-.214.244-.544.373-.988.387V23h5.425v-.387c-.48-.028-.802-.141-.967-.338-.165-.197-.247-.65-.247-1.359v-7.96zM16.158 8.09c-.458 0-.848.163-1.17.489a1.613 1.613 0 0 0-.484 1.176c0 .458.161.849.483 1.17.323.323.713.484 1.171.484.459 0 .85-.161 1.176-.483.326-.322.49-.713.49-1.171 0-.458-.162-.85-.484-1.176a1.598 1.598 0 0 0-1.182-.49z"
    :fill="color"
    fill-rule="evenodd"
  />
</template>

<script>
export default {
  props: {
    color: {
      type: String,
      default: '#E40428',
    }
  }
}
</script>
