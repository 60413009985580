<template>
  <div class="type">
    <div class="type__left">
      <h2>{{ typeData.title }}</h2>
      <div class="type__description" v-html="typeData.short_description"></div>
      <div class="type__checkboxes">
        <BaseCheckbox
          v-for="(subType, index) in typeData.add_on_tours"
          :key="index"
          :label="subType.title"
          :disabled="
            subType.depends_on_title
              ? !subToursSelected.includes(subType.depends_on_title)
              : false
          "
          :checked="subType.sub_tour_selected"
          @checked="handleCheck($event, subType.title)"
        />
      </div>
    </div>
    <div class="type__right">
      <img class="type__map" :src="imageShown" @click="handleClick" />
    </div>
    <button class="type__button type__button--desktop" @click="handleClick">
      <BaseIcon height="32" width="32">
        <IconChevronRightLight />
      </BaseIcon>
    </button>
    <button class="type__button type__button--mobile" @click="handleClick">
      <BaseIcon>
        <IconChevronRightLight />
      </BaseIcon>
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import BaseCheckbox from "@/components/BaseCheckbox.vue"
import BaseIcon from "@/components/BaseIcon.vue"
import IconChevronRightLight from "@/components/icons/IconChevronRightLight.vue"

export default {
  emits: ["clicked"],
  components: {
    BaseCheckbox,
    BaseIcon,
    IconChevronRightLight,
  },
  props: {
    typeData: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      subToursSelected: [],
    }
  },
  computed: {
    ...mapGetters(["language"]),
    subTourFinal() {
      if (this.subToursSelected.length === 1) {
        return this.subToursSelected[0]
      } else if (this.subToursSelected.length > 1) {
        let subToursSelected = [...this.subToursSelected]
        this.typeData.add_on_tours.forEach((addOnTour) => {
          if (addOnTour.depends_on_title) {
            subToursSelected = subToursSelected.filter(
              (subTour) => subTour !== addOnTour.depends_on_title
            )
          }
        })

        return subToursSelected[0]
      }

      return ""
    },
    imageShown() {
      if (this.subTourFinal) {
        return this.typeData.add_on_tours.find(
          (tour) => tour.title === this.subTourFinal
        )?.add_on_image.url
      } else {
        return this.typeData.overview_image.url
      }
    },
  },
  watch: {
    language() {
      this.subToursSelected = []
    },
  },
  methods: {
    handleCheck(bool, subTypeTitle) {
      if (bool) {
        this.subToursSelected.push(subTypeTitle)
      } else {
        this.subToursSelected = this.subToursSelected.filter(
          (subTour) => subTour !== subTypeTitle
        )
      }
    },
    handleClick(e) {
      e.stopPropagation()
      
      this.$store.commit("setTourSelected", this.typeData.title)
      this.$store.commit("setTourSelectedIndex", this.index)
      this.$store.commit("setSubTourSelected", this.subTourFinal)
      if (this.subTourFinal) {
        this.$store.commit(
          "setSubTourSelectedIndex",
          this.typeData.add_on_tours.findIndex(
            (subTour) => subTour.title === this.subTourFinal
          )
        )
      }
    },
  },
}
</script>

<style lang="scss">
.type {
  background-color: $color-white;
  border-radius: $border-radius-m;
  box-shadow: $box-shadow-bottom;
  display: flex;
  justify-content: space-between;
  gap: $spacing-04;
  padding: 20px 60px 20px 20px;
  position: relative;

  @include mq($until: tablet) {
    flex-direction: column;
  }

  &__left {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  &__right {
    height: 190px;
    margin-right: 20px;

    @include mq($until: tablet) {
      margin: 0 auto;
    }
  }

  &__description {
    width: 100%;
    max-width: 280px;
  }

  &__checkboxes {
    display: flex;
    flex-wrap: wrap;
    row-gap: $spacing-03;
    column-gap: $spacing-05;
    margin-top: auto;

    .checkbox {
      font-size: 14px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
  }

  &__map {
    cursor: pointer;
    height: 100%;
  }

  &__button {
    background-color: $color-obsidian-1-25;
    border-radius: $border-radius-xs;
    border: none;
    cursor: pointer;
    height: $spacing-07;
    padding: 8px;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);

    &--desktop {
      display: none;
      height: 60px;
      padding: 14px;
    }

    @include mq(mobile-landscape) {
      &--desktop {
        display: block;
      }
      &--mobile {
        display: none;
      }
    }
  }
}
</style>
