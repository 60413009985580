<template>
  <div
    class="drawer"
    :class="{
      'drawer--lowheight': !isMapMinimized,
      'drawer--fullheight': !isMapVisible,
    }"
  >
    <button
      class="drawer__toggle drawer__toggle__cigarette"
      :class="{ 'drawer__toggle--active': isCigaretteActive }"
      @click="handleToggleCigarette"
    >
      <BaseIcon height="30" width="30" viewBox="0 0 30 30">
        <IconCigarette />
      </BaseIcon>
    </button>

    <button
      class="drawer__toggle drawer__toggle__defibrillator"
      :class="{ 'drawer__toggle--active': isDefibrillatorActive }"
      @click="handleToggleDefibrillator"
    >
      <BaseIcon height="30" width="30" viewBox="0 0 30 30">
        <IconDefibrillator />
      </BaseIcon>
    </button>
    <button
      class="drawer__toggle drawer__toggle__wc"
      :class="{ 'drawer__toggle--active': isWCActive }"
      @click="handleToggleWC"
    >
      <BaseIcon>
        <IconWC />
      </BaseIcon>
    </button>
    <template v-if="isMapVisible && !isMapMinimized">
      <DrawerDescriptionHeader />
    </template>
    <template v-else-if="isContents">
      <DrawerContentsHeader />
      <DrawerContentsList />
    </template>
    <template v-else-if="!isContents">
      <DrawerStationHeader />
      <DrawerStationInfo />
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import BaseIcon from "@/components/BaseIcon.vue"
import IconCigarette from "@/components/icons/IconCigarette.vue"
import IconDefibrillator from "@/components/icons/IconDefibrillator.vue"
import IconWC from "@/components/icons/IconWC.vue"
import DrawerDescriptionHeader from "@/components/DrawerDescriptionHeader.vue"
import DrawerContentsHeader from "@/components/DrawerContentsHeader.vue"
import DrawerContentsList from "@/components/DrawerContentsList.vue"
import DrawerStationHeader from "@/components/DrawerStationHeader.vue"
import DrawerStationInfo from "@/components/DrawerStationInfo.vue"

export default {
  components: {
    BaseIcon,
    IconCigarette,
    IconDefibrillator,
    IconWC,
    DrawerDescriptionHeader,
    DrawerContentsHeader,
    DrawerContentsList,
    DrawerStationHeader,
    DrawerStationInfo,
  },
  computed: {
    ...mapGetters([
      "isMapVisible",
      "isMapMinimized",
      "isCigaretteActive",
      "isDefibrillatorActive",
      "isWCActive",
      "isContents",
    ]),
  },
  methods: {
    handleToggleCigarette() {
      this.$store.commit("setIsCigaretteActive", !this.isCigaretteActive)
    },
    handleToggleDefibrillator() {
      this.$store.commit("setIsDefibrillatorActive", !this.isDefibrillatorActive)
    },
    handleToggleWC() {
      this.$store.commit("setIsWCActive", !this.isWCActive)
    },
  },
}
</script>

<style lang="scss">
.drawer {
  background-color: $color-white;
  box-shadow: $box-shadow-top;
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  height: $drawerheight;
  width: 100%;
  transition: height $transition-speed-fast;

  &--lowheight {
    height: 70px;
  }
  // fullheight should override lowheight
  &--fullheight {
    height: calc($fullheight - 70px);
  }

  &__toggle {
    @include flex-center;
    background-color: $color-white;
    box-shadow: $box-shadow-outer;
    border-radius: $border-radius-xs;
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;

    @include mq($until: mobile-landscape) {
      height: 36px;
      width: 36px;
    }

    &--active {
      background-color: $color-select;
      box-shadow: $box-shadow-inner;

      // https://css-tricks.com/solving-sticky-hover-states-with-media-hover-hover/
      @media (hover: hover) {
        &:hover {
          background-color: $color-hover;
        }
      }
    }

    &__defibrillator {
      position: absolute;
      top: -60px;
      right: 130px;
      z-index: $z-index-icons;
    }

    &__cigarette {
      position: absolute;
      top: -60px;
      right: 80px;
      z-index: $z-index-icons;
    }

    &__wc {
      position: absolute;
      top: -60px;
      right: 30px;
      z-index: $z-index-icons;
    }
  }
}
</style>
