<template>
  <div class="contents-header">
    <h3>{{ translations?.contents }}</h3>
    <button class="contents-header__button">
      <BaseIcon
        height="32"
        width="32"
        viewBox="0 0 32 32"
        @click="closeContents"
      >
        <IconCloseLight />
      </BaseIcon>
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import BaseIcon from "@/components/BaseIcon.vue"
import IconCloseLight from "@/components/icons/IconCloseLight.vue"

export default {
  components: {
    BaseIcon,
    IconCloseLight,
  },
  computed: {
    ...mapGetters(["translations"]),
  },
  methods: {
    closeContents() {
      this.$store.commit("setIsContents", false)
    },
  },
}
</script>

<style lang="scss">
.contents-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 100px 30px 90px;

  @include mq($until: mobile-landscape) {
    padding: $spacing-05;
  }

  &__button {
    background-color: initial;
    border: none;
    cursor: pointer;
    line-height: 0;
  }
}
</style>
