<template>
  <component
    :is="tag"
    :href="href"
    :target="target"
    :type="type"
    class="button"
    :class="[
      'button--style--' + style,
      'button--size--' + size,
      disabled ? 'is-disabled' : '',
      customClass,
    ]"
    @click="emitClick"
  >
    <span class="button__label">
      <slot />
    </span>
  </component>
</template>

<script>
export default {
  props: {
    tag: {
      default: "button",
      type: String,
    },
    href: {
      default: false,
      type: [String, Boolean],
    },
    target: {
      default: false,
      type: [String, Boolean],
    },
    type: {
      default: "button",
      type: [String, Boolean],
    },
    style: {
      default: "red",
      type: String,
    },
    size: {
      default: "large",
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    customClass: {
      default: false,
      type: [String, Boolean],
    },
  },
  methods: {
    emitClick() {
      this.$emit("click")
    },
  },
}
</script>

<style lang="scss">
.button {
  @include flex-center;
  border: none;
  border-radius: $border-radius-xs;
  cursor: pointer;
  font-family: $font-family-arial;
  line-height: 1;
  text-align: center;
  transition: 0.2s;

  &--style {
    &--red {
      background-color: $color-red-1;
      border: 1px solid $color-red-1;
      color: $color-text-white;

      &:hover:not(.is-disabled) {
        background-color: $color-red-2;
        border-color: $color-red-2;
      }

      &.is-disabled {
        background-color: $color-white;
        border-color: $color-text-red-disabled;
        color: $color-text-red-disabled;
        cursor: not-allowed;
        pointer-events: none;
      }
    }

    &--obsidian {
      background-color: $color-obsidian-1-10;
      border: 1px solid $color-obsidian-1-10;
      color: $color-text-dark;

      &:hover:not(.is-disabled) {
        background-color: $color-obsidian-1-25;
        border-color: $color-obsidian-1-25;
      }

      &.is-disabled {
        color: $color-obsidian-1-75;
        cursor: not-allowed;
      }
    }
  }

  &--size {
    &--large {
      font-size: $font-size-base;
      height: 46px; // excluding border
      padding: 0 20px;
    }

    &--medium {
      font-size: 14px;
      height: 38px; // excluding border
      padding: 0 20px;
    }
  }

  &--fullwidth {
    width: 100%;
  }
}
</style>
