<template>
    <svg id="Ebene_1" data-name="Ebene 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50">
        <g id="Defi_6" data-name="Defi 6">
            <rect class="cls-2" width="50" height="50" rx="5" ry="5"/>
            <path class="cls-1" d="M0,5C0,2.24,2.24,0,5,0h40c2.76,0,5,2.24,5,5v40c0,2.76-2.24,5-5,5H5c-2.76,0-5-2.24-5-5V5ZM21.58,46.08c1.75-.92,4.08-2.5,6.42-4.58,2.5-2.08,4.42-4.08,6.17-6.25,2.25-2.67,3.83-5.42,4.25-7.5.17-1.08.17-3,0-4.17-.42-2.25-1.33-4.33-2.75-6-1.67-2.08-4-3.33-6.25-3.42-1.58-.08-3.08.25-4.58,1-1.25.58-2.17,1.17-3.17,2.17-.33.42-.67.67-.67.67,0,0-.33-.25-.67-.58-1.58-1.58-3.42-2.67-5.42-3.08-2.67-.58-5,0-7.17,1.67-2.25,1.92-3.92,5-4.33,8.17-.17,1.33-.08,2.92.25,4,.75,2.92,3.17,6.58,7.08,10.42,3.17,3.17,6.92,6.08,9.58,7.5.33.08.58.25.67.25,0,0,.25-.08.58-.25ZM42.67,13.33v-2.33l2.42-.08h2.5v-4h-2.42c-1.83,0-2.42-.08-2.5-.08V2h-4.42v2.33c0,2.08,0,2.42-.08,2.5,0,.08-.33.08-2.5.08h-2.42v4h2.5c2.17,0,2.42,0,2.5.08v4.67h4.33l.08-2.33Z"/>
            <path class="cls-1" d="M16.75,39.92c-.08-.08.83-2.17,2-4.75,1.08-2.58,2-4.75,2-4.83l.08-.08h-2.92c-2.58-.08-2.83-.08-2.83-.17,0,0,1.75-2.17,4.67-5.67,2.58-3.08,4.75-5.67,4.83-5.75.17-.17.25-.17.25-.08s-.83,2.08-1.83,4.5c-1,2.5-1.83,4.5-1.83,4.5,0,.08.5.08,2.75.08,1.92,0,2.67.08,2.67.08,0,.08-9.67,12.08-9.75,12.17h-.08Z"/>
        </g>
    </svg>
  </template>

  <style scoped>
    .cls-1 {
        fill: #039a3b;
        fill-rule: evenodd;
    }

    .cls-1, .cls-2 {
        stroke-width: 0px;
    }

    .cls-2 {
        fill: #fff;
    }
  </style>