<template>
  <g fill="#010A0D" fill-rule="nonzero">
    <path
      d="m7.114 5.832.732.89c1.176-.667 2.428-.137 3.132.94.69 1.058.463 2.313-.497 3.103-.43.354-.358.876.109 1.122.705.373 1.26 1.112 1.11 1.827-.368 1.755-2.736 2.326-3.33 4.06-.546 1.593.722 2.824 1.668 3.976l.752-.645c-.623-.87-1.719-1.855-1.452-2.956.518-2.138 5.285-2.92 2.647-6.602-.271-.379-.504-.235-.117-.755.876-1.18.746-2.861-.294-4.077-1.075-1.257-2.956-1.935-4.46-.883z"
    />
    <path
      d="M6.973 7.477c-.95.792-1.346 2.115-1.155 3.369.222 1.454 1.1 2.331 1.854 2.804.336.21.23.223-.017.465-1.722 1.682-2.139 3.16-1.095 5.57.468 1.081.826 1.401 2.23 3.105l.803-.689c-.715-.835-1.642-1.837-2.114-2.91-.829-1.882-.148-3.478 1.29-4.669.776-.643.447-1.329.03-1.504-.87-.365-1.641-1.023-1.936-1.95-.333-1.047.177-1.951.81-2.739l-.7-.852zM24.987 9.785l-12.75 10.941 2.196 2.673 12.75-10.941c.73-.626.825-1.72.215-2.463a1.693 1.693 0 0 0-2.411-.21zM11.191 21.612l-.76.652 2.195 2.673.76-.652zM9.968 22.66l-.777.667 2.195 2.674.778-.667z"
    />
  </g>
</template>
