<template>
  <div class="start">
    <div class="start__header">
      <img class="start__logo" src="@/assets/images/logo-brand.svg" />
      <LanguageToggle white v-if="!tourSelected" />
    </div>
    <div v-if="!tourSelected" class="start__bottom">
      <h2 class="start__title">{{ translations?.factory_tour }}</h2>
      <div class="start__list">
        <StartType
          v-for="(tourType, index) in toursData"
          :key="tourType.id"
          :typeData="tourType"
          :index="index"
        />
      </div>
    </div>
    <template v-else>
      <template v-for="(notice, index) in notices" :key="index">
        <BaseDialog
          v-if="!dialogsAcceptance[index] && currentDialog === index"
          red
          :dialogData="notice"
          @closeDialog="handleCloseDialog"
          @acceptDialog="handleAcceptDialog(index)"
        />
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import LanguageToggle from "@/components/LanguageToggle.vue"
import StartType from "@/components/StartType.vue"
import BaseDialog from "@/components/BaseDialog.vue"

export default {
  components: {
    LanguageToggle,
    StartType,
    BaseDialog,
  },
  data() {
    return {
      currentDialog: 0,
      dialogsAcceptance: null,
    }
  },
  mounted() {
    this.$store.dispatch("getTours")
  },
  computed: {
    ...mapGetters([
      "language",
      "translations",
      "toursData",
      "tourSelected",
      "subTourSelected",
      "currentStation",
    ]),
    notices() {
      return this.toursData?.find((tour) => tour.title === this.tourSelected)?.notes
    },
  },
  watch: {
    language() {
      this.$store.dispatch("getTours")
    },
    tourSelected() {
      if (this.tourSelected) {
        if (this.notices) {
          this.setDialogsAcceptanceArray()
        } else {
          this.goToTour()
        }
      }
    },
    dialogsAcceptance: {
      handler() {
        if (this.dialogsAcceptance?.every(Boolean)) this.goToTour()
      },
      deep: true,
    },
  },
  methods: {
    setDialogsAcceptanceArray() {
      if (this.notices.length)
        this.dialogsAcceptance = this.notices.map(() => false)
    },
    handleCloseDialog() {
      // go back to type selection
      this.$store.commit("setTourSelected", null)
      this.$store.commit("setTourSelectedIndex", null)
      this.$store.commit("setSubTourSelected", null)
      this.$store.commit("setSubTourSelectedIndex", null)
      this.currentDialog = 0
      this.dialogsAcceptance = null
    },
    handleAcceptDialog(index) {
      this.currentDialog = index + 1
      this.dialogsAcceptance[index] = true
    },
    async goToTour() {
      const tourData = this.toursData.find(
        (tour) => tour.title === this.tourSelected
      )

      await this.$store.dispatch("getTour", tourData.ID)

      const subTourData = tourData.add_on_tours
        ? tourData.add_on_tours.find(
            (subTour) => subTour.title === this.subTourSelected
          )
        : null

      const tourParams = this.subTourSelected
        ? subTourData.depends_on_title
          ? this.tourSelected +
            "-" +
            subTourData.depends_on_title +
            "-" +
            this.subTourSelected.replace(" ", "_")
          : this.tourSelected + "-" + this.subTourSelected.replace(" ", "_")
        : this.tourSelected

      this.$router.push({
        name: "Tour",
        params: {
          tour: tourParams.toLowerCase(),
          station: this.currentStation.station_id.toLowerCase(),
        },
      })
    },
  },
}
</script>

<style lang="scss">
.start {
  background-image: url("~@/assets/images/start-background.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $color-red-1;
  height: 100%;
  padding: 70px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 90px;
  overflow-y: auto;

  @include mq($until: mobile-landscape) {
    padding: $spacing-05;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__logo {
    align-self: flex-start;
    height: 44px;
  }

  &__title {
    color: $color-text-white;
    margin-bottom: $spacing-07;

    @include mq($until: mobile-landscape) {
      margin-bottom: $spacing-06;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
  }
}
</style>
