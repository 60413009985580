<template>
  <label class="checkbox" :class="{ 'checkbox--disabled': disabled }">
    {{ label }}
    <input type="checkbox" v-model="isChecked" />
    <span class="checkbox__checkmark"></span>
  </label>
</template>

<script>
export default {
  emits: ["checked"],
  props: {
    label: {
      type: String,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    checked: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isChecked: this.checked,
    }
  },
  watch: {
    disabled: {
      handler() {
        if (this.disabled) this.isChecked = false
      },
      immediate: true,
    },
    checked() {
      this.isChecked = this.checked
    },
    isChecked: {
      handler() {
        this.$emit("checked", this.isChecked)
      },
      immediate: true,
    },
  },
}
</script>

<style lang="scss">
.checkbox {
  position: relative;
  margin-top: auto;
  padding-top: 2px;
  padding-bottom: 2px;
  padding-left: 34px;
  cursor: pointer;
  font-size: 18px;
  line-height: 1;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @include mq($until: mobile-landscape) {
    font-size: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  &--disabled {
    color: $color-obsidian-1-30;
    pointer-events: none;

    .checkbox__checkmark {
      border-color: $color-obsidian-1-30;
    }

    input:checked ~ .checkbox__checkmark {
      background-color: $color-obsidian-1-30 !important;
      border-color: $color-obsidian-1-30 !important;
    }
  }

  input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;

    &:checked ~ .checkbox__checkmark {
      background-color: $color-obsidian-6;
      border-color: $color-obsidian-6;

      &::after {
        display: block;
      }
    }

      &:focus {
        ~ .checkbox__checkmark {
          outline: auto;
          outline-color: -webkit-focus-ring-color;
        }
      }
  }

  &__checkmark {
    border: 1px solid $color-obsidian-1-50;
    border-radius: $border-radius-xxs;
    position: absolute;
    top: 0;
    left: 0;
    height: 24px;
    width: 24px;

    &::after {
      content: "";
      display: none;
      position: absolute;
      left: 8px;
      top: 4px;
      width: 6px;
      height: 12px;
      border: 1px solid $color-white;
      border-width: 0 2px 2px 0;
      transform: rotate(45deg);
    }
  }
}
</style>
