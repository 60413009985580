<template>
  <div class="tour">
    <TheNavigation />
    <button
      class="tour__toggle tour__toggle__map"
      v-if="isMapVisible"
      @click="handleToggleMapSize"
    >
      <BaseIcon>
        <IconMaximize v-if="isMapMinimized" />
        <IconMinimize v-else />
      </BaseIcon>
    </button>
    <div
      class="tour__map"
      :class="{ 'tour__map--minimized': isMapMinimized }"
     
    >
      <Map ref="map" v-if="mapPathAndStationId" />
    </div>
    <Drawer />
    <div class="tour__popover" v-if="currentImage" @click.stop="closePopover">
      <div class="tour__popover__content">
      <Swiper 
        ref="swiper"
        :slides-per-view="1"
        :space-between="20"
        :navigation="{nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev'}"
        :initial-slide="initialSlide"
        class="tour__popover__content__slider"
        >
        <SwiperSlide v-for="(image, index) in stationImages" :key="index">
          <img class="tour__popover__image" :src="image.sizes.large" />
          <div class="tour__popover__caption" v-if="image.description" v-html="image.description"></div>
          <BaseIcon
            class="tour__popover__icon"
            height="32"
            width="32"
            viewBox="0 0 32 32"
          >
            <IconCloseLight />
          </BaseIcon>
        </SwiperSlide>
        <!-- Navigation buttons -->
        <div class="swiper-button-prev" @click.stop></div>
        <div class="swiper-button-next" @click.stop></div>
      </Swiper>
        <!-- <img class="tour__popover__image" :src="currentImage.sizes.large" />
        <BaseIcon
          class="tour__popover__icon"
          height="32"
          width="32"
          viewBox="0 0 32 32"
        >
          <IconCloseLight />
        </BaseIcon> -->
      </div>
    </div>
    <BaseDialog
      v-if="showEndTourDialog"
      :dialogData="endTourNote"
      @closeDialog="closeDialog"
      @acceptDialog="restartTour"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import { Swiper, SwiperSlide } from 'swiper/vue';
import SwiperCore from 'swiper';
import { Navigation } from 'swiper/modules';
import TheNavigation from "@/components/TheNavigation.vue"
import BaseDialog from "@/components/BaseDialog.vue"
import BaseIcon from "@/components/BaseIcon.vue"
import IconMaximize from "@/components/icons/IconMaximize.vue"
import IconMinimize from "@/components/icons/IconMinimize.vue"
import IconCloseLight from "@/components/icons/IconCloseLight.vue"
import Map from "@/components/Map.vue"
import Drawer from "@/components/Drawer.vue"
import 'swiper/css';
import 'swiper/css/navigation';

SwiperCore.use([Navigation]);

export default {
  components: {
    TheNavigation,
    BaseDialog,
    BaseIcon,
    IconMaximize,
    IconMinimize,
    IconCloseLight,
    Map,
    Drawer,
    Swiper,
    SwiperSlide,
  },
  created() {
    if (!this.mapPathAndStationId) this.handleReload() // when reloading
  },
  computed: {
    ...mapGetters([
      "language",
      "endTourNote",
      "toursData",
      "tourData",
      "tourSelected",
      "tourSelectedIndex",
      "subTourSelected",
      "subTourSelectedIndex",
      "mapPathAndStationId",
      "stationsData",
      "currentStation",
      "isMapVisible",
      "isMapMinimized",
      "currentImage",
      "showEndTourDialog",
    ]),

    stationImages () {
      return this.currentStation.tabs.filter(tab => tab.type === 'images')[0]?.images
    },
    initialSlide () {
      return this.stationImages.findIndex(image => image === this.currentImage) || 0;
    }
  },
  watch: {
    "$route.params.station"() {
      this.updateCurrentStationIndex()
    },
    async language() {
      await this.changeLanguageData()
      this.changeLanguageUrl()
    },
  },
  methods: {
    handleToggleMapSize() {
      this.$store.commit("setIsMapMinimized", !this.isMapMinimized)
    },
    handleMaximizeMap() {
      if (this.isMapMinimized) this.$store.commit("setIsMapMinimized", false)
    },
    updateCurrentStationIndex() {
      const index = this.stationsData.findIndex(
        (station) =>
          station.station_id.toLowerCase() === this.$route.params.station
      )

      this.$store.commit("setCurrentStationIndex", index)
    },
    async handleReload() {
      await this.$store.dispatch("getTours")

      const tourParams = this.$route.params.tour.split("-")
      const tourDataIndex = this.toursData.findIndex(
        (tour) => tour.title.toLowerCase() === tourParams[0]
      )
      const tourData = this.toursData[tourDataIndex]

      await this.$store.dispatch("getTour", tourData.ID)
      this.$store.commit("setTourSelected", tourData.title)
      this.$store.commit("setTourSelectedIndex", tourDataIndex)

      if (tourParams[1]) {
        const subTourDataIndex = tourData.add_on_tours.findIndex(
          (subtour) =>
            subtour.title.toLowerCase() ===
            tourParams[tourParams.length - 1].replace("_", " ")
        )
        const subTourData = tourData.add_on_tours[subTourDataIndex]
        this.$store.commit("setSubTourSelected", subTourData.title)
        this.$store.commit("setSubTourSelectedIndex", subTourDataIndex)
      } else {
        this.$store.commit("setSubTourSelected", "")
      }

      this.updateCurrentStationIndex()
      this.$refs.map?.highlightCurrentStation()
    },
    async changeLanguageData() {
      await this.$store.dispatch("getTours")
      const tourData = this.toursData[this.tourSelectedIndex]
      this.$store.commit("setTourSelected", tourData.title)
      await this.$store.dispatch("getTour", tourData.ID)

      if (this.subTourSelectedIndex !== null) {
        const subTourData = tourData.add_on_tours[this.subTourSelectedIndex]
        this.$store.commit("setSubTourSelected", subTourData.title)
      }

      this.$refs.map.highlightCurrentStation()
    },
    changeLanguageUrl() {
      const subTourData = this.tourData.add_on_tours[this.subTourSelectedIndex]

      const tourParams = this.subTourSelected
        ? subTourData.depends_on_title
          ? this.tourSelected +
            "-" +
            subTourData.depends_on_title +
            "-" +
            this.subTourSelected.replace(" ", "_")
          : this.tourSelected + "-" + this.subTourSelected.replace(" ", "_")
        : this.tourSelected

      this.$router.push({
        name: "Tour",
        params: {
          tour: tourParams.toLowerCase(),
          station: this.currentStation.station_id.toLowerCase(),
        },
      })
    },
    closePopover() {
      this.$store.commit("setCurrentImage", null)
    },
    closeDialog() {
      this.$store.commit("setShowEndTourDialog", false)
    },
    restartTour() {
      this.$store.commit("setCurrentStationIndex", 0)
      this.closeDialog()
    }
  },
}
</script>

<style lang="scss">
.tour {
  background-color: #f6f7f5; // background color of map svg
  height: 100%;
  overflow: hidden;
  position: relative;

  &__map {
    height: calc($fullheight - 70px - 70px);
    margin: 70px 0;

    &--minimized {
      cursor: pointer;
      height: calc($fullheight - $drawerheight - 70px);

      // svg {
      //   pointer-events: none;
      // }
    }
  }

  &__toggle {
    @include flex-center;
    background-color: $color-white;
    box-shadow: $box-shadow-outer;
    border-radius: $border-radius-xs;
    border: none;
    cursor: pointer;
    height: 40px;
    width: 40px;

    @include mq($until: mobile-landscape) {
      height: 36px;
      width: 36px;
    }

    &__map {
      position: absolute;
      top: 85px;
      right: 30px;
      z-index: $z-index-icons;
    }
  }

  &__popover {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-overlay;
    cursor: pointer;
    z-index: $z-index-overlay;

    &__content {
      margin: 20px;
      max-width: 550px;
      width: 100%;
      position: relative;
      overflow: hidden;

      &__slider {
        --swiper-navigation-size: 24px;
          --swiper-theme-color: $color-obsidian-3;
        .swiper-wrapper {
          align-items: center;
        }

        .swiper-button-next, .swiper-button-prev {
          background-color: $color-obsidian-1-10;
          border-radius: $border-radius-xs;
          border: none;
          cursor: pointer;
          height: 40px;
          width: 40px;

          @include mq($until: mobile-landscape) {
            height: 36px;
            width: 36px;
          }
        }

        .swiper-button-diabled {
            opacity: 0;
              pointer-events: none;

              @include mq($until: mobile-landscape) {
                display: none;
            }
        }
      }
    }

    &__image {
      height: 100%;
      width: 100%;
      position: relative;
    }

    &__icon {
      position: absolute;
      top: 20px;
      right: 20px;
      z-index: 10;
      background-color: $color-obsidian-1-10;
      border-radius: $border-radius-xs;
    }

    &__caption {
      position: absolute;
      width: 100%;
      bottom: 5px;
      left: 0;
      z-index: 10;
      background-color: rgba($color-black, 0.4);
      color: $color-obsidian-1-50;
      padding: 8px 16px;
      font-size: 14px;
    }
  }
}
</style>
