<template>
  <div class="description-header" @click="handleClick">
    <p class="description-header__title">{{ translations?.description }}</p>
    <button class="description-header__button">
      <BaseIcon>
        <IconChevronUpRegular />
      </BaseIcon>
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
import BaseIcon from "@/components/BaseIcon.vue"
import IconChevronUpRegular from "@/components/icons/IconChevronUpRegular.vue"

export default {
  components: {
    BaseIcon,
    IconChevronUpRegular,
  },
  computed: {
    ...mapGetters(["translations"]),
  },
  methods: {
    handleClick() {
      this.$store.commit("setIsMapMinimized", true)
    },
  },
}
</script>

<style lang="scss">
.description-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  height: 70px;
  margin: auto 30px auto 90px;

  @include mq($until: mobile-landscape) {
    margin: auto $spacing-05;
  }

  &__title {
    font-size: 18px;
  }

  &__button {
    background-color: initial;
    border: none;
    line-height: 0;
  }
}
</style>
