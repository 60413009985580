import axios from "axios"

const baseApi = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    apitoken: process.env.VUE_APP_API_KEY,
  },
})

export function getTranslations(lang) {
  return baseApi.get(lang + process.env.VUE_APP_BODY_URL + "/translations")
}

export function getTours(lang) {
  return baseApi.get(lang + process.env.VUE_APP_BODY_URL + "/tours")
}

export function getTour(lang, tourId) {
  return baseApi.get(lang + process.env.VUE_APP_BODY_URL + "/tours/" + tourId)
}
