<template>
  <div class="app">
    <div class="app__content">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  mounted() {
    const localStorageLanguage = localStorage.getItem("language")
    if (localStorageLanguage) {
      this.$store.commit("setLanguage", localStorageLanguage)
    }
    this.$store.dispatch("getTranslations")
    this.setVh()

    window.addEventListener("resize", () => {
      this.$store.commit("setWindowHeight", window.innerHeight)
      this.$store.commit("setWindowWidth", window.innerWidth)
      this.setVh()
    })
  },
  computed: {
    ...mapGetters(["language"]),
  },
  watch: {
    language() {
      localStorage.setItem("language", this.language)
      this.$store.dispatch("getTranslations")
    },
  },
  methods: {
    setVh() {
      document.documentElement.style.setProperty(
        "--vh",
        `${window.innerHeight * 0.01}px`
      )
    },
  },
}
</script>

<style lang="scss">
.app {
  background-image: linear-gradient(
    to bottom,
    $color-obsidian-3,
    $color-obsidian-6
  );
  overflow: hidden;

  &__content {
    margin: 0 auto;
    max-width: 834px;
    height: $fullheight;
    width: 100%;
  }
}
</style>
