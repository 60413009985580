<template>
  <nav class="nav">
    <div class="nav__left">
        <button class="nav__home" @click="goToStart">
        <BaseIcon height="32" width="32" viewBox="0 0 32 32">
          <IconLogo />
        </BaseIcon>
        <h3 class="nav__home__title">{{ translations?.factory_tour }}</h3>
      </button>
      <div class="nav__tour" v-if="tourData?.title">
        <div>| {{ tourData?.title }}</div>
      </div>
    </div>
    <div class="nav__right">
      <LanguageToggle />
      <button
        class="nav__button"
        :class="{ 'nav__button--active': isMapVisible }"
        @click="handleToggleMap"
      >
        <BaseIcon height="32" width="32" viewBox="0 0 32 32">
          <IconLocation />
        </BaseIcon>
        <p class="nav__button__label">{{ translations?.map }}</p>
      </button>
    </div>
  </nav>
</template>

<script>
import { mapGetters } from "vuex"
import BaseIcon from "@/components/BaseIcon.vue"
import IconLogo from "@/components/icons/IconLogo.vue"
import IconLocation from "@/components/icons/IconLocation.vue"
import LanguageToggle from "@/components/LanguageToggle.vue"

export default {
  components: {
    BaseIcon,
    IconLogo,
    IconLocation,
    LanguageToggle,
  },
  computed: {
    ...mapGetters(["translations", "isMapVisible", "tourData"]),
  },
  methods: {
    goToStart() {
      this.$store.dispatch("resetTour")
      this.$store.commit("setCurrentStationIndex", 0)
      this.$router.push({ name: "Start" })
    },
    handleToggleMap() {
      this.$store.commit("setIsMapVisible", !this.isMapVisible)
    },
  },
}
</script>

<style lang="scss">
.nav {
  background-color: $color-white;
  box-shadow: $box-shadow-bottom;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 15px 15px 30px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: $z-index-navigation;

  @include mq($until: mobile-landscape) {
    padding: 15px $spacing-05;
  }

  &__home {
    background-color: initial;
    border: none;
    display: flex;
    cursor: pointer;
    position: relative;

    &__title {
      align-self: flex-end;
      line-height: 1;
      font-weight: 400;
      margin-left: 6px;

      @include mq($until: mobile-landscape) {
        align-self: auto;
        padding-top: 8px;
        font-size: 18px;
      }
    }
  }

  &__tour {
    color: $color-obsidian-3;
    display: none;
    padding-left: 7px;
    font-size: 24px;
    font-family: $font-family-universltpro;
    margin-top: 3px;
    font-weight: 300;

    @include mq(tablet) {
      display: block;
    }

    div {
      margin-top: 3px;
    }
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__right {
    display: flex;
    align-items: center;
    column-gap: 30px;

    @include mq($until: mobile-landscape) {
      column-gap: 8px;
      padding-left: 16px;
    }
  }

  &__button {
    background-color: $color-obsidian-1-10;
    border-radius: $border-radius-xs;
    border: none;
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: $spacing-03;
    padding: $spacing-02 20px $spacing-02 10px;

    &--active {
      background-color: $color-select;
      box-shadow: $box-shadow-inner;

      &:hover {
        background-color: $color-hover;
      }
    }
  }
}
</style>
