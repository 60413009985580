<template>
  <div class="list">
    <button
      v-for="(station, index) in stationsData"
      :key="station.ID"
      class="list__item"
      :class="{ 'list__item--active': index === currentStationIndex }"
      @click="handleClick(station.station_id)"
    >
      <div class="list__item__number">{{ index + 1 }}</div>
      <p class="list__item__name" v-html="station.title"></p>
    </button>
  </div>
</template>

<script>
import { mapGetters } from "vuex"

export default {
  computed: {
    ...mapGetters(["stationsData", "currentStationIndex"]),
  },
  methods: {
    handleClick(stationParam) {
      this.$store.commit("setIsContents", false)
      this.$router.push({
        name: "Tour",
        params: {
          tour: this.$route.params.tour,
          station: stationParam.toLowerCase(),
        },
      })
    },
  },
}

</script>

<style lang="scss">
.list {
  height: calc(100% - 92px);
  padding: 0 90px 90px;
  overflow-y: auto;

  @include mq($until: mobile-landscape) {
    padding: 0 $spacing-05 $spacing-05;
  }

  &__item {
    background-color: initial;
    border: 1px solid transparent;
    border-top: 1px solid $color-obsidian-1-25;
    cursor: pointer;
    display: flex;
    align-items: center;
    column-gap: $spacing-03;
    padding: 20px;
    width: 100%;

    &--active {
      background-color: $color-negative-fill;
      border: 1px solid $color-negative-outline;
      border-radius: $border-radius-xs;

      .list__item__number {
        background-color: $color-red-1;
      }
    }

    &__number {
      @include flex-center;
      background-color: $color-red-3;
      border-radius: $border-radius-m;
      color: $color-text-white;
      font-weight: $font-weight-bold;
      height: 30px;
      width: 30px;
    }

    &__name {
      font-size: 18px;
      text-align: left;
    }
  }
}
</style>
