import { createStore } from "vuex"
import { getTranslations, getTours, getTour } from "@/api"

export const store = createStore({
  state: {
    language: "de",
    translations: null,
    mapSvgData: null,
    toursData: null,
    tourData: null,
    tourSelected: null,
    tourSelectedIndex: null,
    subTourSelected: null,
    subTourSelectedIndex: null,
    currentStationIndex: 0,
    isMapVisible: true,
    isMapMinimized: true,
    isCigaretteActive: false,
    isDefibrillatorActive: false,
    isWCActive: false,
    isContents: false,
    windowHeight: window.innerHeight,
    windowWidth: window.innerWidth,
    currentImage: null,
    endTourNote: null,
    showEndTourDialog: false,
  },
  actions: {
    async getTranslations({ commit, state }) {
      try {
        const response = await getTranslations(state.language)
        commit("setTranslations", response.data.translations)
      } catch (error) {
        console.error(error)
      }
    },
    async getTours({ dispatch, commit, state }) {
      try {
        const response = await getTours(state.language)
        commit("setToursData", response.data.tours)
        commit("setEndTourNote", response.data.end_of_tour_note)
        dispatch("getMap", response.data.map_svg.url)
      } catch (error) {
        console.error(error)
      }
    },
    async getTour({ commit, state }, tourId) {
      try {
        const response = await getTour(state.language, tourId)
        commit("setTourData", response.data.tour)
      } catch (error) {
        console.error(error)
      }
    },
    async getMap({ commit }, mapUrl) {
      // credits to https://stackoverflow.com/questions/67943859/how-to-load-an-external-svg-into-vue-as-an-object
      try {
        const response = await fetch(mapUrl)
        const mapSvgData = await response.text()
        commit("setMapSvgData", mapSvgData)
      } catch (error) {
        console.error(error)
      }
    },
    resetTour({ commit }) {
      commit("setTourSelected", null)
      commit("setTourSelectedIndex", null)
      commit("setSubTourSelected", null)
      commit("setSubTourSelectedIndex", null)
    },
  },
  mutations: {
    setLanguage(state, payload) {
      state.language = payload
    },
    setTranslations(state, payload) {
      state.translations = payload
    },
    setMapSvgData(state, payload) {
      state.mapSvgData = payload
    },
    setEndTourNote(state, payload) {
      state.endTourNote = payload
    },
    setToursData(state, payload) {
      state.toursData = payload
    },
    setTourData(state, payload) {
      state.tourData = payload
    },
    setTourSelected(state, payload) {
      state.tourSelected = payload
    },
    setTourSelectedIndex(state, payload) {
      state.tourSelectedIndex = payload
    },
    setSubTourSelected(state, payload) {
      state.subTourSelected = payload
    },
    setSubTourSelectedIndex(state, payload) {
      state.subTourSelectedIndex = payload
    },
    setCurrentStationIndex(state, payload) {
      state.currentStationIndex = payload
    },
    setIsMapVisible(state, payload) {
      state.isMapVisible = payload
    },
    setIsMapMinimized(state, payload) {
      state.isMapMinimized = payload
    },
    setIsCigaretteActive(state, payload) {
      state.isCigaretteActive = payload
    },
    setIsDefibrillatorActive(state, payload) {
      state.isDefibrillatorActive = payload
    },
    setIsWCActive(state, payload) {
      state.isWCActive = payload
    },
    setIsContents(state, payload) {
      state.isContents = payload
    },
    setWindowHeight(state, payload) {
      state.windowHeight = payload
    },
    setWindowWidth(state, payload) {
      state.windowWidth = payload
    },
    setCurrentImage(state, payload) {
      state.currentImage = payload
    },
    setShowEndTourDialog(state, payload) {
      state.showEndTourDialog = payload
    },
  },
  getters: {
    language(state) {
      return state.language
    },
    translations(state) {
      return state.translations
    },
    mapSvgData(state) {
      return state.mapSvgData
    },
    endTourNote(state) {
      return state.endTourNote
    },
    toursData(state) {
      return state.toursData
    },
    tourData(state) {
      return state.tourData
    },
    tourSelected(state) {
      return state.tourSelected
    },
    tourSelectedIndex(state) {
      return state.tourSelectedIndex
    },
    subTourSelected(state) {
      return state.subTourSelected
    },
    subTourSelectedIndex(state) {
      return state.subTourSelectedIndex
    },
    mapPathAndStationId(state) {
      if (state.subTourSelected) {
        const subTour = state.tourData.add_on_tours.find(
          (tour) => tour.title === state.subTourSelected
        )

        return {
          path_id: subTour?.add_on_path_id,
          station_id: subTour?.add_on_station_id,
        }
      } else if (state.subTourSelected === "") {
        return {
          path_id: state.tourData.path_id,
          station_id: state.tourData.station_id,
        }
      }
    },
    stationsData(state) {
      if (state.subTourSelected) {
        return state.tourData.add_on_tours.find(
          (tour) => tour.title === state.subTourSelected
        )?.add_on_stations
      } else if (state.subTourSelected === "") {
        return state.tourData?.stations
      }
    },
    stationsTotal(state, getters) {
      return getters.stationsData?.length
    },
    currentStationIndex(state) {
      return state.currentStationIndex
    },
    currentStation(state, getters) {
      if (getters.stationsData) {
        return getters.stationsData[state.currentStationIndex]
      } else {
        return null
      }
    },
    isMapVisible(state) {
      return state.isMapVisible
    },
    isMapMinimized(state) {
      return state.isMapMinimized
    },
    isCigaretteActive(state) {
      return state.isCigaretteActive
    },
    isDefibrillatorActive(state) {
      return state.isDefibrillatorActive
    },
    isWCActive(state) {
      return state.isWCActive
    },
    isContents(state) {
      return state.isContents
    },
    windowHeight(state) {
      return state.windowHeight
    },
    windowWidth(state) {
      return state.windowWidth
    },
    currentImage(state) {
      return state.currentImage
    },
    showEndTourDialog(state) {
      return state.showEndTourDialog
    },
  },
})
